/* eslint-disable @typescript-eslint/explicit-function-return-type, id-length */
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@app/core/guards/auth.guard';
import { UserInfoGuard } from '@app/core/guards/user-info-guard.service';
import { LicensePlateGuard } from '@app/core/guards/license-plate.guard';
import { ReturnedVehicleGuard } from '@app/core/guards/returned-vehicle.guard';

const routes: Routes = [
  {
    path: 'welcome',
    loadChildren: () => import('./welcome/welcome.module').then(module => module.WelcomeModule),
    data: { animation: 'Welcome' }
  },
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then(module => module.LoginModule),
    data: { animation: 'Login' }
  },
  {
    path: 'user-info',
    loadChildren: () => import('./user-info/user-info.module').then(module => module.UserInfoModule),
    canActivate: [AuthGuard],
    data: {
      animation: 'UserInfo',
      step: 1
    }
  },
  {
    path: 'license-plate',
    loadChildren: () => import('./license-plate/license-plate.module').then(module => module.LicensePlateModule),
    canActivate: [AuthGuard, UserInfoGuard],
    data: {
      animation: 'LicensePlate',
      step: 2
    }
  },
  {
    path: 'returned-vehicle',
    loadChildren: () => import('./returned-vehicle/returned-vehicle.module').then(module => module.ReturnedVehicleModule),
    canActivate: [AuthGuard, LicensePlateGuard],
    data: {
      animation: 'ReturnedVehicle',
      step: 3
    }
  },
  {
    path: 'new-vehicle',
    loadChildren: () => import('./new-vehicle/new-vehicle.module').then(module => module.NewVehicleModule),
    canActivate: [AuthGuard, ReturnedVehicleGuard],
    data: {
      animation: 'NewVehicle',
      step: 4
    }
  },
  {
    path: 'options-list',
    loadChildren: () => import('./options-list/options-list.module').then(module => module.OptionsListModule),
    canActivate: [AuthGuard, ReturnedVehicleGuard],
    data: {
      animation: 'OptionsList',
      step: 5
    }
  },
  {
    path: 'confirmation',
    loadChildren: () => import('./confirmation/confirmation.module').then(module => module.ConfirmationModule),
    canActivate: [AuthGuard, ReturnedVehicleGuard],
    data: {
      animation: 'Confirmation',
      step: 6
    }
  },
  {
    path: 'success',
    loadChildren: () => import('./success/success.module').then(module => module.SuccessModule),
    data: { animation: 'Success' }
  },
  {
    path: '',
    pathMatch: 'full',
    redirectTo: '/user-info'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {

}
